import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../../components/Input";
import SignupButton from "../../components/SignupButton";
import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import { useIsMount } from "../../hooks/useIsMount";

import { signUp } from "../../redux/actions/authActions";
const Register = () => {
  const [email, setEmail] = useState();
  const [username, setUserName] = useState();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  useEffect(() => {
    document.title =
      "Admin Sign Up - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);

  const { userInfo, loading, error } = useSelector((state) => state.signUp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPasswordToggled, setToggle] = useState(false);
  const [isPasswordConfirmToggled, setConfirmToggle] = useState(false);

  const signInState = useSelector((state) => state.signIn);
  useEffect(() => {
    if (signInState?.userInfo?.user) {
      navigate("/admin/admin-dashboard");
    }
    // eslint-disable-next-line
  }, []);
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        alert(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  useEffect(() => {
    if (!isMount) {
      if (userInfo) {
        navigate("/admin/admin-dashboard");
      }
    }
    // eslint-disable-next-line
  }, [userInfo]);
  const handleSubmit = () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
    }
    const newUserData = { password, email, username };
    dispatch(signUp(newUserData));
  };
  return (
    <Wrapper>
      <Form>
        <Title title="Welcome!" />
        <Subtitle text="Register so as to become an admin" />
        <Input
          placeholder="Username"
          onChange={(e) => setUserName(e.target.value)}
        />
        <Input
          placeholder="Email address"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <PasswordInputWrapper>
          <TextInput
            placeholder="Enter password"
            required
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordToggled ? "text" : "password"}
          />
          <ToggleIcon onClick={() => setToggle(!isPasswordToggled)}>
            {isPasswordToggled ? <AiFillEyeInvisible /> : <AiFillEye />}
          </ToggleIcon>
        </PasswordInputWrapper>
        <PasswordInputWrapper>
          <TextInput
            placeholder="Confirm password"
            type={isPasswordConfirmToggled ? "text" : "password"}
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <ToggleIcon
            onClick={() => setConfirmToggle(!isPasswordConfirmToggled)}
          >
            {isPasswordConfirmToggled ? <AiFillEyeInvisible /> : <AiFillEye />}
          </ToggleIcon>
        </PasswordInputWrapper>
        <SignupButton
          title={loading ? "Signing Up...." : "Sign Up"}
          disabled={
            !email || !username || !password || !confirmPassword || loading
          }
          onClick={handleSubmit}
        />
        <Link to="/admin/admin-signin">
          <AccountExists>
            Already have an account? <span>Sign in</span>
          </AccountExists>
        </Link>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;
const Form = styled.form`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
`;

const PasswordInputWrapper = styled.div`
  width: 400px;
  height: 55px;
  position: relative;
  margin-bottom: 24px;
`;

const TextInput = styled.input`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  padding: 12px 22px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 30px;
  width: 100%;
  color: #141414;
  &::placeholder {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;

    color: #979797;
  }
`;
const ToggleIcon = styled.div`
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 20px;
  color: #979797;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
const AccountExists = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #141414;
  cursor: pointer;
  margin-top: 20px;
  span {
    color: #0074ba;
  }
`;
export default Register;
